import React from 'react';
import config from '../config';
function ErrorPage() {
  return (
    <div className="error-container">
      <h1 className="error-title">404 - Page Not Found</h1>
      <p className="error-message">Sorry, the page you are looking for does not exist.</p>
      <a className="error-link" href={config.ROUTES.HOME}>Go back to Home</a>
    </div>
  );
}

export default ErrorPage;

import CompanyLogo from '../assests/white_Logo 1.png'
import React from 'react';
import config from '../config'
import { useNavigate } from 'react-router-dom';
function Contact() {
    const navigate = useNavigate();
    function handleClick(){
      navigate(config.ROUTES.HOME)
      
    }
    return (
    <div className='header-contact-container'>
      <img src={CompanyLogo} alt="Company Logo" onClick={handleClick}/>
      <div className="contact-container">
        <h2 className="contact-title">Thank You For Your Interest!</h2>
        <p className="contact-message">We appreciate your inquiry. Please schedule a call with us to discuss further.</p>
        <a href={config.SCHEDULE_LINK} target="_blank" rel="noopener noreferrer" className="schedule-button">
          Schedule a Call
        </a>
      </div>
    </div>
    );
  }
  
  export default Contact;

import React, { useState , useEffect } from 'react';
import styled from 'styled-components';
import jsonp from 'jsonp';
import config from '../../config';

const WaitlistForm = styled.form`
  border-radius: 16px;
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  justify-content: flex-start;
  margin: auto 0;
  @media (max-width: 750px) {
    width: auto;
  }
`;

const FormRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 13px;
  align-self: stretch;
    @media (max-width: 750px) {
      max-width: 100%;
    }
`;

const FormGroup = styled.div`
  display: flex;
  width:auto;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 0%;
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const Label = styled.label`
  width: 20px;
  padding: 5px 0;
  margin-top:10px;
  @media (max-width: 750px) {
    padding-right: 20px;
    font-size:15px;
  }
`;

const Input = styled.input`
  border-radius: 16px;
  display: flex;
  height: 30px;
  margin-top: 12px;
  font-size : 24px;
  width: 250px;
  border: 1px solid rgba(105, 110, 255, 0.40);
  background: #FFF;
  padding: 10px;
  @media (max-width: 750px) {
    width: 88px;
    height: 10px;
    border-radius:8px;
    font-size:18px;
  }
`;

const SubmitButton = styled.button`
  border-radius: 16px;
  gap: 40px;
  border:none;
  overflow: hidden;
  color: #fff;
  background-color: #000;
  flex-grow:0.7;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  padding:13px 70px;
  @media (max-width: 750px) {
    width: 100%;
    padding: 6px 10px;
    font-size: 15px;
    border-radius: 8px;
  }
`;

function LawyerForm() {
  const [email, setEmail] =  useState('');
  const [name, setName] =  useState('');
  const [organisation, setOrganisation] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const url = config.MAILCHIMP_URL;
  const clearFeilds = ()=>{
    setEmail('');
    setName('');
    setOrganisation('');
  }
  useEffect(() => {
    if (message) {

      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);
  const handleSubmit = (e)=> {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    jsonp(`${url}&FNAME=${name}&EMAIL=${email}&COMPANY=${organisation}`, { param: 'c' }, (err, data) => {
        const { msg, result } = data;
        if (result === 'success') {
          console.log("Submission success:", data);
          setMessage(msg);
        } else {
          console.warn("Submission error:", data);
          setMessage(msg);
        }
      clearFeilds();
  });

  };

  return (
        <WaitlistForm onSubmit={handleSubmit}>
          <FormRow>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input 
                type="text" 
                id="name" 
                name="FNAME" 
                required
                value = {name}
                pattern="^[A-Za-z\s]+$" 
                title="Name should only contain letters and spaces." 
                onChange={(e)=>{setName(e.target.value)}} 
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input 
                style={{
                  border: error ? '1px solid red' : '1px solid rgba(105, 110, 255, 0.40)',
                }}
                type="email" 
                id="email" 
                name="EMAIL" 
                value ={email}
                required 
                onChange={(e)=>{setEmail(e.target.value); setError("");}}
              />
            </FormGroup>
          </FormRow>
          <FormRow >
            <FormGroup>
              <Label htmlFor="organisation">Firm/Organisation</Label>
              <Input 
                type="text" 
                id="organisation" 
                name="COMPANY"
                value={organisation} 
                pattern="^[A-Za-z0-9\s]+$" 
                title="Organization name should only contain letters ,numbers and spaces." 
                onChange={(e)=>{setOrganisation(e.target.value)}}
                required
               />
            </FormGroup>
            <SubmitButton type="submit">Join Waitlist</SubmitButton>
          </FormRow>
          {message && <p className='message'>{message}</p>}
          {error&&<span className='email-error'>{error}</span>}
        </WaitlistForm>
  );
}

export default LawyerForm;



import React from 'react';
import styled from 'styled-components';

const StatisticsWrapper = styled.section`
  display: flex;
  width:auto;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  padding: 48px 48px 100px 72px;
  gap:84px;
  margin: 20px 25px;
  border-radius: 60px;
  background: #F5F5F5;
  @media (max-width: 750px) {
    max-width: 100%;
    padding: 20px 20px;
    margin: 0px 10px;
    border-radius: 20px;
    gap:45px;
    margin: 40px 12px;
  }
`;

const StatisticItem = styled.div`
  display: flex;
  min-height: 172px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 750px) {
    max-width: 100%;
    margin-top: ${props => props.mobileMarginTop || props.marginTop || '0'};
  }
`;

const StatisticContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 48px;
  justify-content: ${props => props.justify || 'center'};
  flex-wrap: wrap;
  @media (max-width: 750px) {
    max-width: 100%;
    justify-content:flex-start;
    gap:20px;
  }
`;

const StatisticNumber = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 308px;
  margin: auto 0;
  @media (max-width: 750px) {
    max-width: 100%;
    min-width:30px;
    width:150px;
    font-size: 35px;
  }
  @media (min-width:751px) and (max-width: 900px) {
    font-size: 60px;
    width:200px;
  }
`;

const Number = styled.div`
  align-self: stretch;
  gap: 10px;
  white-space: nowrap;
  font: 900 96px Inter;
  background: linear-gradient(270deg, #444 52.5%, #000 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 750px) {
    font-size: 35px;
    white-space: initial;
  }
  @media (min-width:751px) and (max-width: 900px) {
    font-size: 60px;
  }
  
`;

const Label = styled.div`
  align-self: flex-start;
  margin-top: 12px;
  gap: 10px;
  color: #444;
  font: 700 36px Instrument Sans;
  @media (max-width: 750px) {
    font-size: 20px;
  }
  @media (min-width:751px) and (max-width: 900px) {
    font-size: 24px;
  }
  
`;

const Description = styled.div`
  align-self: stretch;
  flex: 1;
  min-width: 240px;
  gap: 10px;
  color: #000;
  margin: auto;
  font: 700 36px Instrument Sans;
  @media (max-width: 780px) {
    max-width: 100%;
    font-size: 20px;
    min-width:70px;
  }
  @media (min-width:750px) and (max-width: 900px) {
    font-size: 24px;
  }
`;

function Stats() {
  const statistics = [
    {
      number: '99.9%',
      label: 'time saved',
      description: 'Create title flows and compare revenue records in seconds.',
    },
    {
      number: '>90%',
      label: 'costs reduced',
      description: 'Collaborate with your team to analyse multiple projects and lands at a fraction of the cost.',
    },
    {
      number: '25k+',
      label: 'records tested',
      description: 'Agents that can handle all tasks, extract data from multiple documents with high accuracy.',
    },
  ];

  return (
    <StatisticsWrapper>
      {statistics.map((stat, index) => (
        <StatisticItem key={index}>
          <StatisticContent justify={index > 0 ? 'flex-start' : 'center'}>
            <StatisticNumber>
              <Number>{stat.number}</Number>
              <Label>{stat.label}</Label>
            </StatisticNumber>
            <Description>{stat.description}</Description>
          </StatisticContent>
        </StatisticItem>
      ))}
    </StatisticsWrapper>
  );
}

export default Stats;
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import ErrorPage from './components/ErrorPage';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path="/*" element={<ErrorPage/>}></Route>
      </Routes>

    </Router>
  );
}

export default App;

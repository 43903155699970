import React from 'react';
import styled from 'styled-components';

const FeatureWrapper = styled.section`
  background-color: #fff;
  display: flex;
  width: auto;
  flex-direction: column;
  overflow: hidden;
  font-family: Instrument Sans;
  color: #000;
  justify-content: flex-start;
  padding: 48px 48px 100px 48px;
  margin: 5px;
  @media (max-width: 750px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const FeatureTitle = styled.h2`
  align-self: flex-start;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  color: black;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 18px;
    line-height:25px;
  }
`;

const FeatureCards = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  gap: 30px 50px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 750px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureCard = styled.div`
  border-radius: 16px;
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  width: 480px;
  margin: 10px;
  padding: 24px;
  border: 0.25px solid  #696EFF;;
  box-shadow: 0px 1px 16px 2px rgba(149, 152, 255, 0.25);
`;

const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin:0px;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 16px;
  }
`;

const CardDescription = styled.p`
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  margin-top: 24px;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 18px;
    line-height:22px;
  }
`;

function Body() {
  const features = [
    {
      title: 'For Businesses',
      description: 'Enterprise-level analysis providing comprehensive insights for commercial and residential projects, plotted layouts, secured loans, and more.',
    },
    {
      title: 'For Homebuyers',
      description: "Flow of title that's precise and easy to understand, at a fraction of the cost. Don't let legal jargons drain you out from buying your dream house.",
    },
  ];

  return (
    <FeatureWrapper>
      <FeatureTitle>
        Leverage proprietary AI for secure <br /> and dispute-free real estate investment.
      </FeatureTitle>
      <FeatureCards>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <CardTitle>{feature.title}</CardTitle>
            <CardDescription>{feature.description}</CardDescription>
          </FeatureCard>
        ))}
      </FeatureCards>
    </FeatureWrapper>
  );
}

export default Body;
import React from 'react';
import styled from 'styled-components';
import MicrosofImg from '../assests/MSFS 1.png'
import RizeImg from '../assests/razorpay 1.png'
import HubSpotImg from '../assests/hubspot 1.png'

const PartnerWrapper = styled.section`
  background-color: #fff;
  display: flex;
  width: auto;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 84px 48px;
  gap:84px;
  @media (max-width: 750px) {
    max-width: 100%;
    padding: 0 20px;
    margin-left:20px;
    margin-right:20px;
  }
  @media (min-width:768px) and (max-width:991px){
  padding:50px;
  }

  @media (min-width: 820px) and (max-width: 991px) {
    max-width: 100%;
    margin-left:40px;
    margin-right:20px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 100%;
    align-items: normal;
    margin:40px;
  }
`;

const PartnerContent = styled.div`
  display: flex;
  align-items: center;
  width:1255.843px;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap:80px;
  @media (max-width: 991px) {
    max-width: 100%;
    gap:50px;
    justify-content: center;
  }
  @media (min-width:992px) and (max-width:1200px) {
    gap:60px;
  }
`;

const DesignedByLawyers = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-family: Instrument Sans, sans-serif;
  justify-content: flex-start;
  width: 574px;
  gap:69px;
  @media (max-width: 750px) {
    max-width: 100%;
    width: 110px;
    gap:100px;
    align-self:baseline;
  }
  @media (min-width:751px) and (max-width:991px) {
    max-width: 100%;
    width: 300px;
  }
  @media (min-width:992px) and (max-width:1200px) {
    max-width: 100%;
    width: 350px;
  }
  }
`;

const Title = styled.h2`
  align-self: stretch;
  flex: 1;
  width: 100%;
  gap: 10px;
  font-size: 36px;
  color: #444;
  font-weight: 700;
  @media (max-width: 750px) {
    max-width: 100%;
    font-weight: 600;
    font-size: 20px;
    margin:0px;
  }
`;

const Description = styled.p`
  align-self: stretch;
  flex: 1;
  margin-top:20px;
  width: 100%;
  gap: 10px;
  font-size: 28px;
  color: #000;
  font-weight: 500;
  @media (max-width: 750px) {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom:0px;
    font-size:18px;
  }
`;

const PartnersSection = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 48px;

  @media (max-width: 750px) {
    max-width: 100%;
    gap:20px;
    min-width:0px;
    width:100px;
  }
  @media (min-width:751px) and (max-width: 991px) {
    font-weight: 600;
    font-size: 20px;
    width:300px;
  }
`;

const PartnersTitle = styled.h2`
  display: flex;
  width: 406px;
  max-width: 100%;
  flex-direction: column;
  color: #444;
  justify-content: flex-start;
  font: 700 36px Instrument Sans, sans-serif;
  @media (max-width: 750px) {
    font-weight: 600;
    font-size: 20px;
    margin:0;
  }
`;

const PartnerLogos = styled.div`
  display: flex;
  margin-top: 27px;
  align-items: flex-start;
  gap: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 750px) {
    max-width: 100%;
  }
`;

const PartnerLogo = styled.img`
  object-fit: contain;
  object-position: center;
  align-self: stretch;
  @media (max-width: 750px) {
    margin-bottom:20px;
    width:75px;
  }
  @media (min-width: 751px) and (max-width: 991px){
    margin-bottom:20px;
    width:120px;
  }
  @media (min-width:992px) and (max-width:1100px) {
    width:150px;
  }
`;

function Partners() {
  const partners = [
    { src: MicrosofImg},
    { src: RizeImg },
    { src: HubSpotImg},
  ];

  return (
    <PartnerWrapper>
      <PartnerContent>
        <DesignedByLawyers>
          <Title>Designed by lawyers</Title>
          <Description>
            With specialised experience in real estate disputes and transactions.
          </Description>
        </DesignedByLawyers>
        <PartnersSection>
          <PartnersTitle>Partnered with the best</PartnersTitle>
          <PartnerLogos>
            {partners.map((partner, index) => (
              <PartnerLogo
                key={index}
                src={partner.src}
                minWidth={partner.minWidth}
                alt={`Partner logo ${index + 1}`}
              />
            ))}
          </PartnerLogos>
        </PartnersSection>
      </PartnerContent>
    </PartnerWrapper>
  );
}

export default Partners;
import React, { useState } from "react";
import styled from 'styled-components';
import BusinessForm from "./forms/BusinessForm";
import LawyerForm from "./forms/LawyerForm";
import IndividualForm from "./forms/IndividualForm";

const WaitlistWrapper = styled.section`
  font-family:"Instrument Sans";
  display: flex;
  padding: 84px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 84px;
  align-self: stretch;
  @media (max-width: 750px) {
    max-width: 100%;
    padding: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding:40px;
  }
`;

const WaitlistContent = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 168px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin:40px;


@media (max-width: 750px) {
    justify-content: center;
  }

@media (min-width: 820px) and (max-width: 991px) {
    margin-left:80px;
  }
@media (min-width : 1000px) and (max-width: 1200px){
    gap:40px;
    margin:0px;
    justify-content: center;
}

`;
const WaitlistTitle = styled.h2`
  align-self: stretch;
  width: 296px;
  min-width: 240px;
  gap: 10px;
  font-size: 36px;
  font-weight: 700;
  margin: auto 0;
  background: linear-gradient(270deg, #444 52%, #000 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 750px) {
    font-size:26px;
    font-weight: 700;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width:auto;
  }
  @media (min-width : 1000px) and (max-width: 1200px){
    width:auto;
}
`;

const GroupedForms = styled.div`
  border-radius: 16px;
  min-width: 240px;
  font-size: 24px;
  font-weight: 500;
  margin: auto 0;
  padding: 36px 48px;
  border: 0.25px solid #696EFF;
  box-shadow: 0px 1px 16px 3px rgba(149, 152, 255, 0.25);
   @media (max-width: 750px) {
   padding: 20px 35px;
}
`
const ButtonContainer =styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom:10px;
    margin-top:10px;
    gap:12px;
   @media (max-width: 750px) {
    flex-direction:column;
    gap:10px;
  }
`
const ButtonTitle =styled.div`
@media (max-width: 750px) {
    font-size:15px;
  }
`
const ButtonType = styled.button`
    font-size:24px;
    border-radius:16px;
    border: 1px solid rgba(105, 110, 255, 0.40);
    padding:10px 33px;
    font-weight:500;
    @media (max-width: 750px) {
        font-size:15px;
        border-radius:8px;
        padding:6px 0px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
   padding:12px 30px;
  }
  @media (min-width : 1000px) and (max-width: 1200px){
    padding:12px 25px;
    align-self:stretch;
}
`


const Grouped = () => {
  const [activeTab, setActiveTab] = useState("Individual");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <WaitlistWrapper>
      <WaitlistContent>
      <WaitlistTitle id='join-waitlist'>Revolutionize your real estate investments.</WaitlistTitle>
      <GroupedForms>
        <ButtonTitle>I'm:</ButtonTitle>
        <ButtonContainer>
        <ButtonType
          className={activeTab === "Individual" ? "active" : "inactive"}
          onClick={() => handleTabClick("Individual")}
        >
          Individual
        </ButtonType>
        <ButtonType
          className={activeTab === "Lawyer/Firm" ? "active" : "inactive"}
          onClick={() => handleTabClick("Lawyer/Firm")}
        >
         Lawyer/Firm
        </ButtonType>
        <ButtonType
          className={activeTab === "Business" ? "active" : "inactive"}
          onClick={() => handleTabClick("Business")}
        >
         Business
        </ButtonType>
        </ButtonContainer>
        {activeTab === "Individual" && <IndividualForm />}
        {activeTab === "Lawyer/Firm" && <LawyerForm/>}
        {activeTab === "Business" && <BusinessForm/>}
        </GroupedForms>
    </WaitlistContent>
    </WaitlistWrapper>
    
  );
};

export default Grouped;

const config = {
    SCHEDULE_LINK : 'https://cal.com/dattaprasad',
    MAILCHIMP_URL : "https://litigrit.us20.list-manage.com/subscribe/post-json?u=11a9b6aaaeb99ddd1bad8ba64&amp;id=f49e6f8242&amp;f_id=00e612eaf0",
    MAIL:"mailto:contact@litigrit.com",
    ROUTES : {
        HOME : '/',
        CONTACT : '/contact'
    }

};

export default config;
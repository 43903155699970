import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import Stats from './Stats';
import Partners from './Patners';
import Grouped from './Grouped';
function HomePage() {
  return (
    <>
      <Header />
      <Body />
      <Stats />
      <Partners />
      <Grouped/>
      <Footer />
    </>
  )
}

export default HomePage

import React from 'react';
import CompanyLogo from '../assests/logo1.png'
import { useNavigate } from 'react-router-dom';
import config from '../config';

function Header() {
  const navigate = useNavigate();
    function handleClick(){
      navigate(config.ROUTES.HOME)
    }
  return (
    <div className='header-container'>
      <nav className='navbar'>
        <img src={CompanyLogo} alt="Company Logo" onClick={handleClick} style={{ cursor: 'pointer' }}/>
        <a href='#join-waitlist'>Join Waitlist</a>
      </nav>
      <div className='text-container'>
        <h2>Blazing fast real estate title due diligence</h2>
        <div className='head-text'>
          Instant, easy, and accurate, so you can{" "}
          <span>
            buy with confidence.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
import React from 'react';
import styled from 'styled-components';
import CompanyLogo from '../assests/white_Logo 1.png'
import TermsPdf from '../assests/documents/Titlewize Terms and Conditions.pdf'
import PolicyPdf from '../assests/documents/Titlewize_Privacy Policy.pdf'
import config from '../config';

const FooterWrapper = styled.footer`
  background-color: #000;
  display: flex;
  width: auto;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  justify-content: flex-start;
  padding: 24px 50px 24px 50px;
  font: 600 20px Instrument Sans, sans-serif;
  border-radius: 40px 40px 0px 0px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
    border-radius: 20px 20px 0px 0px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 3.5;
  object-fit: contain;
  object-position: center;
  width: 126px;
  max-width: 100%;
  margin-top:20px;
  @media (max-width: 775px) {
    max-width: 100%;
    height:25px;
    padding-left:0px;
    width:87.5px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  margin-top: 84px;
  width: auto;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-top: ${props => props.marginTop || '0'};
  width: ${props => props.width || '100%'};
  max-width: 100%;
  @media (max-width: 750px) {
    padding-right: ${props => props.paddingRight || '0'};
    font-size:15px;
  }
`;

const Copyright = styled.p`
  align-self: flex-start;
  margin-top: 84px;
  gap: 10px;
  padding: 10px;
  @media (max-width: 750px) {
    max-width: 100%;
    margin-top: 40px;
    font-size:15px;
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <Logo src={CompanyLogo} alt="Company Logo" />
        <FooterLinks>
          <LinkGroup>
            <FooterLink href={TermsPdf} target='_blank' rel="noopener noreferrer">Terms of Service</FooterLink>
            <FooterLink href={PolicyPdf} target='_blank' rel="noopener noreferrer" marginTop="24px">Privacy Policy</FooterLink>
          </LinkGroup>
          <LinkGroup>
            <FooterLink href={config.ROUTES.CONTACT}>Schedule a Call</FooterLink>
            <FooterLink href={config.MAIL} marginTop="24px" className="email-us">Email Us</FooterLink>
          </LinkGroup>
        </FooterLinks>
        <Copyright>
          © LitiGrit Technologies Pvt. Ltd. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;